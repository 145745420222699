import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { hideVisually, fluidRange } from 'polished';

import Container from '../components/container';
import Icon from '../components/icon';
import Link from '../components/link';
import Row from '../components/row';

import logo from '../assets/icons/logo.svg';
import theme from '../configs/theme';
import Column from './column';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    width: 100%;
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors.bg};
    font-family: 'Times New Roman', serif;

    .wf-active & {
      font-family: ${({ theme }) => theme.fonts.primary};
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  .hideVisually {
    ${hideVisually()};
  }

  p {
    max-width: 640px;
    margin: 0 auto 32px auto;  
    padding: 0;
    line-height: 1.75;
    letter-spacing: 0.05em;

    ${({ theme }) => fluidRange(
      [
        {
          prop: 'font-size',
          fromSize: '16px',
          toSize: '18px',
        },
        // {
        //   prop: 'margin-bottom',
        //   fromSize: '32px',
        //   toSize: '64px',
        // },
      ],
      theme.breakpoints.sm,
      theme.breakpoints.xxl,
    )}
  }

  li {
    margin-bottom: 15px;

    p {
      margin: 0;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.third};
  }

  .orangeFill {
    fill: ${props => props.theme.colors.secondary};
  }

  .blueStroke {
    fill: none;
    stroke: ${props => props.theme.colors.primary};
    stroke-miterlimit: 10;
    stroke-width: 4px;
  }
`;

const Document = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex: auto;
`;

const Footer = styled.footer`
  flex-shrink: 0;
`;

const FooterContainer = styled(Container)`
  height: 100%;
  padding: 15px 0;
  text-align: center;

  svg {
    width: 150px;
    margin: 0 auto;
  }
`;

const Layout = (props) => {
  const { data } = props;

  return (
    <Document>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
      </Helmet>
      <Main>
        {props.children}
      </Main>
      <Footer>
        <FooterContainer fluid>
          <Row>
            <Column>
              <Icon icon={logo} />
            </Column>
          </Row>
          <Row>
            <Column>
            <br /><br />
              <Link to="/regulamin-sklepu" title="Regulamin sklepu">regulamin sklepu</Link>
              {" "}
              <Link to="/polityka-prywatnosci" title="Polityka prywatności">polityka prywatności</Link>
            </Column>
          </Row>
        </FooterContainer>
      </Footer>
    </Document>
  );
}

const LayoutWrapper = (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <Location>
            { locationProps => <Layout {...locationProps} {...props} data={data}  /> }
          </Location>
        </>
      </ThemeProvider>
    )}
  />
);

LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWrapper;
